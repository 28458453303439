import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface AuthState {
  user: any;
  token: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: any;
  currentRoute: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  currentRoute: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoading = true;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      if (action.payload) {
        state.isAuthenticated = true;
        state.isLoading = false;
        state.token = action.payload.token;
      }
    },
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
    },
  },
});

export const { login, setUser, setCurrentRoute } = authSlice.actions;

export default authSlice.reducer;

export const authUser = (state: RootState) => state.auth.user;
export const authToken = (state: RootState) => state.auth.token;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
