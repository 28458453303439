/**
 * Copyright 2022-2023 Highway9 Networks Inc.
 */

import { DataObject } from "./dataObject";

export type MetricGraph = DataObject & {
  name: string;
  srcObject: string;
  categories: MetricCategory[];
  vendorType : string;
  isSystem? : boolean;
  isDefault? : boolean;
  status? : string;
  isDraft?: boolean;
};

export type MetricCategory = {
  name: string;
  id?: string;
  columnCount: 1 | 2 | 3;
  icon?: string | JSX.Element;
  isExpand?: boolean;
  graphs: CategoryGraph[];
};

export type CategoryGraph = {
  name: string;
  id?:string;
  graphType: string;
  showSuccess?: boolean;
  showUtilization?: boolean;
  metrics: MetricOption[];
  graphOptions?: Highcharts.Options;
  isExpand?: boolean;
};
export type SelectedTestGraphData ={
  graph : CategoryGraph;
  sourceObj : string
}

export enum AggregationType {
  SUM,
  AVERAGE,
  NONE
}
export type MetricOption = {
  key: string;
  name: string;
  target?: number;
  unit?: string;
  aggregationType? : AggregationType | string
  seriesOptions?: Highcharts.SeriesLineOptions
  isCustomFormula? : boolean
  id?:string;
  isExpand?: boolean;
};
export const defaultMetrics :MetricOption ={ name: "", key: "",aggregationType: "SUM", id: `${Date.now()}` }
export const defaultGraphs: CategoryGraph = {
    name: "",
    graphType: "line",
    showSuccess: false,
    showUtilization: false,
    metrics: [defaultMetrics]

}
export const defaultGroup: MetricCategory = {
    name: "",
    columnCount: 3,
    icon: '',
    isExpand: true,
    graphs :[defaultGraphs]
}
export const metricsCatagory: MetricGraph = {
    categories: [defaultGroup],
    name: "",
    srcObject: "radio",
    vendorType: 'Highway9',

}
export type CoreKPIsList = {
  aggregationFunction : string;
  isPerPlmn : boolean;
  name : string;
  vendor : string;

}

