/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { RadioGroup } from "../types/radioGroup";
import { RequestOptions } from "../types/requestOptions";
import { ISite } from "../types/site";
import { authenticationService } from "./authentication-service";

export const siteService = {
  getSites,
  addSite,
  updateSite,
  deleteSite,

  getRadioGroups,
  addRadioGroup,
  updateRadioGroup,
  deleteRadioGroup,
};

async function getSites(): Promise<ISite[]> {
  const requestOptions: RequestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };
  try {
    const response = await fetch(`/api/v1/orch/radioSites`, requestOptions);
    const text = await response.text();
    const sites = text && JSON.parse(text).data;
    return sites as ISite[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function addSite(site: ISite) {
  const requestOptions: RequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(site),
  };

  try {
    const resp = await fetch(`/api/v1/orch/radioSites`, requestOptions);
    const data = (await resp.json()) as ISite;
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function updateSite(site: ISite) {
  // Remove properties that are not available in the API
  const updatedSite = { ...site };
  delete updatedSite.countAPs;
  delete updatedSite.connectedAPs;
  delete updatedSite.children;
  delete updatedSite.overallStatus;

  const requestOptions: RequestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(updatedSite),
  };

  return fetch(`/api/v1/orch/radioSites/${site.id}`, requestOptions);
}

function deleteSite(id: string) {
  const requestOptions: RequestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  return fetch(`/api/v1/orch/radioSites/${id}`, requestOptions);
}

async function getRadioGroups() {
  const requestOptions: RequestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  try {
    const response = await fetch(`/api/v1/orch/radioGroups`, requestOptions);
    const text = await response.text();
    const zones = text && JSON.parse(text).data;
    return zones as RadioGroup[];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

function addRadioGroup(data: RadioGroup) {
  const requestOptions: RequestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(data),
  };

  return fetch(`/api/v1/orch/radioGroups`, requestOptions);
}

function updateRadioGroup(data: RadioGroup) {
  // Remove properties that are not available in the API
  const newObj = { ...data };
  delete newObj.countAPs;
  delete newObj.connectedAPs;
  delete newObj.radioCoordinates;
  delete newObj.radios;
  delete newObj.overallStatus;

  const requestOptions: RequestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
    body: JSON.stringify(newObj),
  };

  return fetch(`/api/v1/orch/radioGroups/${data.id}`, requestOptions);
}

function deleteRadioGroup(id: string) {
  const requestOptions: RequestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + authenticationService.currentUserValue.token,
    },
  };

  return fetch(`/api/v1/orch/radioGroups/${id}`, requestOptions);
}
