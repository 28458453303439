/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Box, Drawer, List, ListItem, ListItemIcon, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../constants/routes";
import { softwareVersion } from "../store/context/dashboardSlice";
import { useAppSelector } from "../store/hooks";
import { Divider, Grid } from "@h9/ui-lib";
export const DRAWER_WIDTH = 57; // 56px + 1px (for right border)

type Props = {};

const styles = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    textAlign: "center",
    "& .MuiDrawer-paper": {
      width: DRAWER_WIDTH,
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  listContainer: () => ({
    padding: "8px 10px",
    "& a": {
      color: "#0000008a",
    },
  }),
  listItem: () => ({
    padding: "8px",
    width: "36px",
    height: "36px",
    marginX: "auto",
    marginBottom: "4px",
    borderRadius: "50%",
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E9F0EE",
      "& .MuiSvgIcon-root": {
        fill: "#226A58",
      },
    },
  }),
  divider: () => ({
    marginBottom: "4px",
  }),
} satisfies Record<string, SxProps<Theme>>;

const SideDrawerNew = (props: Props) => {
  const location = useLocation();
  const version = useAppSelector(softwareVersion);
  return (
    <Drawer variant="permanent" sx={styles.drawer}>
      <Grid height="100%" container flexDirection="column" justifyContent="space-between">
        {/* The following code is picked from the existing Side Drawer component */}
        <List sx={styles.listContainer}>
          {routes.map((route, index) => {
            if (route.name === "|") return <Divider key={index} sx={styles.divider} />;
            return (
              <ListItem sx={styles.listItem} key={route.name} selected={location.pathname === route.path}>
                <ListItemIcon>
                  <Tooltip title={route.name} aria-label={route.name} placement="right" arrow key={route.name}>
                    <Link to={route.path ?? "#"}>{route.icon}</Link>
                  </Tooltip>
                </ListItemIcon>
                {/*<ListItemText primary={''} />*/}
              </ListItem>
            );
          })}
        </List>
      </Grid>

      <Box>
        <Divider />
        <Box pt={"4px"} pb={"4px"} mb={"4px"}>
          <Typography variant="caption" sx={{ fontSize: "9px", fontFamily: "Inter, sans-serif" }}>
            {version}
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideDrawerNew;