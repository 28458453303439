import { Device } from "~/types/device";
import APIService from "./APIServices";
import { authenticationService } from "./authentication-service";
import { DeviceGroup } from "~/types/deviceGroup";

const deviceAPI = new APIService<Device>("devices");
const deviceGroupAPI = new APIService<DeviceGroup>("deviceGroups");

export const subscriberService = {
  getSubscribers: deviceAPI.getAll,
  createSubscriber: deviceAPI.create,
  updateSubscriber: deviceAPI.update,
  deleteDevice: deviceAPI.delete,
  getMetrics: deviceAPI.getMetrics,
  importSubscribers,
  getDeviceGroups: deviceGroupAPI.getAll,
  addDeviceGroup: deviceGroupAPI.create,
  updateDeviceGroup: deviceGroupAPI.update,
  deleteDeviceGroup: deviceGroupAPI.delete,
};

function importSubscribers(file: File) {
  const formData = new FormData();
  formData.append("upfile", file);

  const requestOptions = {
    method: "POST",
    headers: { accept: "*/*", Authorization: "Bearer " + authenticationService.currentUserValue.token },
    body: formData,
  };

  return fetch(`/api/v1/orch/devices/operations/import`, requestOptions);
}
