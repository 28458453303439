/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { EventData, EventQuery } from "~/types/event";
import APIService from "./APIServices";

const eventAPI = new APIService<EventData>("events");

async function getEvents(query: EventQuery) {
  const data = (await eventAPI.post("query", query)) as unknown as EventData[];

  return data;
}

export const eventServices = {
  getEvents,
};
