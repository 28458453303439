import { jsxs as x, jsx as o } from "react/jsx-runtime";
/* empty css                      */import { OutlinedInput as y, InputAdornment as f, IconButton as w, TextField as g } from "@mui/material";
import { useState as b } from "react";
import "../../../map-de2346a3.js";
import "../../../server.browser-e466af8c.js";
import { Typography as I } from "../Typography/Typography.js";
import "../../../style.module-61e1e2b8.js";
import "../../DateTimeSelector/index.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "moment-timezone";
import { E as A, a as M } from "../../../chunk-1-cf357621.js";
function $(r) {
  const {
    variant: i = "outlined",
    width: s,
    type: d = "text",
    height: a,
    sx: e,
    label: p,
    ...t
  } = r, [n, m] = b(!1), u = () => {
    m(!n);
  }, c = (h) => {
    h.preventDefault();
  };
  return /* @__PURE__ */ x(I, { sx: P, type: "smallBodyText2", children: [
    /* @__PURE__ */ o("span", { children: p }),
    d == "password" ? /* @__PURE__ */ o(
      y,
      {
        id: "outlined-adornment-password",
        type: n ? "text" : "password",
        endAdornment: /* @__PURE__ */ o(f, { position: "end", children: /* @__PURE__ */ o(
          w,
          {
            "aria-label": "toggle password visibility",
            onClick: u,
            onMouseDown: c,
            edge: "end",
            children: n ? /* @__PURE__ */ o(A, {}) : /* @__PURE__ */ o(M, {})
          }
        ) }),
        sx: [
          { width: s, height: a },
          l[i],
          ...Array.isArray(e) ? e : [e]
        ],
        placeholder: t.placeholder ? t.placeholder : "",
        ...t
      }
    ) : /* @__PURE__ */ o(
      g,
      {
        sx: [
          { width: s, height: a },
          l[i],
          ...Array.isArray(e) ? e : [e]
        ],
        ...t
      }
    )
  ] });
}
const l = {
  outlined: (r) => ({
    borderColor: `${r.palette.grey[600]}`,
    borderRadius: "8px",
    textTransform: "none",
    gap: 3,
    marginTop: "2px !important",
    fontSize: "14px",
    ".MuiInputBase-root": {
      borderRadius: 2
    },
    "&>.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#14141499"
    }
  }),
  filled: () => ({}),
  standard: () => ({})
}, P = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& > span": {
    marginLeft: ".5rem"
  }
};
export {
  $ as Input
};
