export type DeviceMetrics = {
  [key: string]: number | string | undefined;
  ue_connection_state?: number;
  ue_downlink_rate?: number;
  ue_eci?: number;
  ue_id?: number;
  ue_p1downlink_bler?: number;
  ue_p2downlink_bler?: number;
  ue_pathloss?: number;
  ue_pdownlink_cqi?: number;
  ue_pdownlink_mcs?: number;
  ue_radio_connection?: string;
  ue_rsrp0?: number;
  ue_rsrp1?: number;
  ue_s1downlink_bler?: number;
  ue_s2downlink_bler?: number;
  ue_sdownlink_cqi?: number;
  ue_sdownlink_mcs?: number;
  ue_tx_power?: number;
  ue_uplink_bler?: number;
  ue_uplink_sinr?: number;
  ue_uplink_mcs?: number;
  ue_uplink_rate?: number;
  timestamp?: number;

  'device_phydlnew{cell=~"1"}'?: number; // Physical Downlink
  'device_phyul{cell=~"1"}'?: number; // Physical Uplink
  'device_dlper{cell=~"1"}'?: number;  // Downlink Block Error Rate (BLER) 
  'device_ulcrcper{cell=~"1"}'?: number; // Uplink Block Error Rate
  'device_ulmcs{cell=~"1"}'?: number; // Uplink Modulation and Coding Scheme
  'device_dlmcscw_0{cell=~"1"}'?: number; // Downlink Modulation and Coding Scheme CW0  
  'device_dlmcscw_1{cell=~"1"}'?: number; // Downlink Modulation and Coding Scheme CW1
  'device_dlcqicw_0{cell=~"1"}'?: number; // Downlink Channel Quality Indicator CW0
  'device_dlcqicw_1{cell=~"1"}'?: number; // Downlink Channel Quality Indicator CW1
  'device_c2i{cell=~"1"}'?: number; // Cell to Interference Ratio
  'device_rssi{cell=~"1"}'?: number; // Received Signal Strength Indicator
  'device_rirx{cell=~"1"}'?: number; // Rank Indicator - Receiver
  'device_ritx{cell=~"1"}'?: number; // Rank Indicator - Transmit

  'device_rnti{cell=~"1"}'?: number; // Radio Network Temporary Identifier

  'device_phydlnew{cell=~"2"}' ?: number;
  'device_phyul{cell=~"2"}' ?: number;
  'device_dlper{cell=~"2"}' ?: number;
  'device_ulcrcper{cell=~"2"}' ?: number;
  'device_ulmcs{cell=~"2"}' ?: number;
  'device_dlmcscw_0{cell=~"2"}' ?: number;
  'device_dlmcscw_1{cell=~"2"}' ?: number;
  'device_dlcqicw_0{cell=~"2"}' ?: number;
  'device_dlcqicw_1{cell=~"2"}' ?: number;
  'device_c2i{cell=~"2"}' ?: number;
  'device_rssi{cell=~"2"}' ?: number;
  'device_rirx{cell=~"2"}' ?: number;
  'device_ritx{cell=~"2"}' ?: number;
};

export const deviceMetricsMap = {
  'device_phydlnew{cell=~"1"}': "Physical Downlink",
  'device_phyul{cell=~"1"}': "Physical Uplink",
  'device_dlper{cell=~"1"}': "Downlink Block Error Rate (BLER)",
  'device_ulcrcper{cell=~"1"}': "Uplink Block Error Rate",
  'device_ulmcs{cell=~"1"}': "Uplink Modulation and Coding Scheme",
  'device_dlmcscw_0{cell=~"1"}': "Downlink Modulation and Coding Scheme CW0",
  'device_dlmcscw_1{cell=~"1"}': "Downlink Modulation and Coding Scheme CW1",
  'device_dlcqicw_0{cell=~"1"}': "Downlink Channel Quality Indicator CW0",
  'device_dlcqicw_1{cell=~"1"}': "Downlink Channel Quality Indicator CW1",
  'device_c2i{cell=~"1"}': "Cell to Interference Ratio",
  'device_rssi{cell=~"1"}': "Received Signal Strength Indicator",
  'device_rirx{cell=~"1"}': "Rank Indicator - Receiver",
  'device_ritx{cell=~"1"}': "Rank Indicator - Transmit",
  'device_rnti{cell=~"1"}': 'Radio Network Temporary Identifier',
  
  'device_phydlnew{cell=~"2"}': "Physical Downlink (Cell 2)",
  'device_phyul{cell=~"2"}': "Physical Uplink (Cell 2)",
  'device_dlper{cell=~"2"}': "Downlink Block Error Rate (BLER) (Cell 2)",
  'device_ulcrcper{cell=~"2"}': "Uplink Block Error Rate (Cell 2)",
  'device_ulmcs{cell=~"2"}': "Uplink Modulation and Coding Scheme (Cell 2)",
  'device_dlmcscw_0{cell=~"2"}': "Downlink Modulation and Coding Scheme CW0 (Cell 2)",
  'device_dlmcscw_1{cell=~"2"}': "Downlink Modulation and Coding Scheme CW1 (Cell 2)",
  'device_dlcqicw_0{cell=~"2"}': "Downlink Channel Quality Indicator CW0 (Cell 2)",
  'device_dlcqicw_1{cell=~"2"}': "Downlink Channel Quality Indicator CW1 (Cell 2)",
  'device_c2i{cell=~"2"}': "Cell to Interference Ratio (Cell 2)",
  'device_rssi{cell=~"2"}': "Received Signal Strength Indicator (Cell 2)",
  'device_rirx{cell=~"2"}': "Rank Indicator - Receiver (Cell 2)",
  'device_ritx{cell=~"2"}': "Rank Indicator - Transmit (Cell 2)",
} as const;

export const deviceAirspanMetrics = Object.keys(deviceMetricsMap);


type DeviceMetricsMap = typeof deviceMetricsMap;
type ReversedDeviceMetricsMap = {
  [K in DeviceMetricsMap[keyof DeviceMetricsMap]]: {
    [Key in keyof DeviceMetricsMap]: DeviceMetricsMap[Key] extends K ? Key : never;
  }[keyof DeviceMetricsMap];
};

export const DeviceMetricsKeys: ReversedDeviceMetricsMap = Object.entries(deviceMetricsMap).reduce((acc, [key, value]) => {
  // @ts-ignore
  acc[value] = key 
  return acc;
}, {} as ReversedDeviceMetricsMap);