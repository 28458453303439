import * as h from "react";
const b = h.createContext(void 0), P = {};
b.Provider;
const M = () => h.useContext(b) ? h.useContext(b) : P, v = typeof window > "u" ? global : window, R = "@griffel/";
function U(e, n) {
  return v[Symbol.for(R + e)] || (v[Symbol.for(R + e)] = n), v[Symbol.for(R + e)];
}
const G = /* @__PURE__ */ U("DEBUG_RESET_CLASSES", {}), C = /* @__PURE__ */ U("DEFINITION_LOOKUP_TABLE", {}), p = "data-make-styles-bucket", H = "r", S = 7, g = "___", B = "_", V = process.env.NODE_ENV === "production" ? g.length + S : g.length + S + B.length + S, X = 0, D = 1;
function N(e) {
  for (var n = 0, t, s = 0, r = e.length; r >= 4; ++s, r -= 4)
    t = e.charCodeAt(s) & 255 | (e.charCodeAt(++s) & 255) << 8 | (e.charCodeAt(++s) & 255) << 16 | (e.charCodeAt(++s) & 255) << 24, t = /* Math.imul(k, m): */
    (t & 65535) * 1540483477 + ((t >>> 16) * 59797 << 16), t ^= /* k >>> r: */
    t >>> 24, n = /* Math.imul(k, m): */
    (t & 65535) * 1540483477 + ((t >>> 16) * 59797 << 16) ^ /* Math.imul(h, m): */
    (n & 65535) * 1540483477 + ((n >>> 16) * 59797 << 16);
  switch (r) {
    case 3:
      n ^= (e.charCodeAt(s + 2) & 255) << 16;
    case 2:
      n ^= (e.charCodeAt(s + 1) & 255) << 8;
    case 1:
      n ^= e.charCodeAt(s) & 255, n = /* Math.imul(h, m): */
      (n & 65535) * 1540483477 + ((n >>> 16) * 59797 << 16);
  }
  return n ^= n >>> 13, n = /* Math.imul(h, m): */
  (n & 65535) * 1540483477 + ((n >>> 16) * 59797 << 16), ((n ^ n >>> 15) >>> 0).toString(36);
}
function y(e) {
  const n = e.length;
  if (n === S)
    return e;
  for (let t = n; t < S; t++)
    e += "0";
  return e;
}
function k(e, n, t = []) {
  return process.env.NODE_ENV === "production" ? g + y(N(e + n)) : g + y(N(e + n)) + B + y(N(t.join("")));
}
function j(e, n) {
  let t = "";
  for (const s in e) {
    const r = e[s];
    if (r) {
      const o = Array.isArray(r);
      n === "rtl" ? t += (o ? r[1] : r) + " " : t += (o ? r[0] : r) + " ";
    }
  }
  return t.slice(0, -1);
}
function I(e, n) {
  const t = {};
  for (const s in e) {
    const r = j(e[s], n);
    if (r === "") {
      t[s] = "";
      continue;
    }
    const o = k(r, n), c = o + " " + r;
    C[o] = [e[s], n], t[s] = c;
  }
  return t;
}
const _ = {};
function K() {
  let e = null, n = "", t = "";
  const s = new Array(arguments.length);
  let r = "";
  for (let i = 0; i < arguments.length; i++) {
    const u = arguments[i];
    if (typeof u == "string" && u !== "") {
      const d = u.indexOf(g);
      if (d === -1)
        process.env.NODE_ENV !== "production" && u.split(" ").forEach((m) => {
          m.startsWith(H) && G[m] && (r ? console.error(`mergeClasses(): a passed string contains multiple classes produced by makeResetStyles (${u} & ${n}, this will lead to non-deterministic behavior. Learn more:https://griffel.js.org/react/api/make-reset-styles#limitations
Source string: ${u}`) : r = m);
        }), n += u + " ";
      else {
        const m = u.substr(d, V);
        d > 0 && (n += u.slice(0, d)), t += m, s[i] = m;
      }
      process.env.NODE_ENV !== "production" && u.indexOf(g, d + 1) !== -1 && console.error(`mergeClasses(): a passed string contains multiple identifiers of atomic classes (classes that start with "${g}"), it's possible that passed classes were concatenated in a wrong way. Source string: ${u}`);
    }
  }
  if (t === "")
    return n.slice(0, -1);
  const o = _[t];
  if (o !== void 0)
    return n + o;
  const c = [];
  for (let i = 0; i < arguments.length; i++) {
    const u = s[i];
    if (u) {
      const d = C[u];
      d ? (c.push(d[X]), process.env.NODE_ENV !== "production" && e !== null && e !== d[D] && console.error(`mergeClasses(): a passed string contains an identifier (${u}) that has different direction (dir="${d[1] ? "rtl" : "ltr"}") setting than other classes. This is not supported. Source string: ${arguments[i]}`), e = d[D]) : process.env.NODE_ENV !== "production" && console.error(`mergeClasses(): a passed string contains an identifier (${u}) that does not match any entry in cache. Source string: ${arguments[i]}`);
    }
  }
  const a = Object.assign.apply(
    Object,
    // .assign() mutates the first object, we can't mutate mappings as it will produce invalid results later
    [{}].concat(c)
  );
  let l = j(a, e);
  const f = k(l, e, s);
  return l = f + " " + l, _[t] = l, C[f] = [a, e], n + l;
}
const A = {}, T = /* @__PURE__ */ new Set(), E = {
  getChildrenSequences: (e) => {
    const n = Object.keys(_).find((t) => _[t].startsWith(e));
    return n ? n.split(g).filter((t) => t.length).map((t) => g + t) : [];
  },
  addCSSRule: (e) => {
    T.add(e);
  },
  addSequenceDetails: (e, n) => {
    Object.entries(e).forEach(([t, s]) => {
      A[s.substring(0, V)] = {
        slotName: t,
        sourceURL: n
      };
    });
  },
  getCSSRules: () => Array.from(T),
  getSequenceDetails: (e) => A[e]
};
function F(e, n) {
  return Array.isArray(e) ? n === "rtl" ? e[1] : e[0] : e;
}
function Q(e, n, t, s) {
  const r = e[0], o = e[1];
  return Object.entries(r).map(([c, a]) => {
    const l = F(a, o);
    let f;
    if (t && n) {
      const i = t.find(({
        className: u
      }) => u === l);
      !i && n[0][c] ? f = F(n[0][c], n[1]) : i && n[0][c] ? f = (s ? s.filter(({
        debugClassNames: d
      }) => d.filter(({
        className: m
      }) => m === l).length > 0).length > 0 : !1) ? i.className : i.overriddenBy : (!i && !n[0][c] || i && !n[0][c]) && (f = void 0);
    }
    return {
      className: l,
      overriddenBy: f
    };
  });
}
function z(e, n) {
  const t = C[e];
  if (t === void 0)
    return;
  const s = n ? C[n.sequenceHash] : void 0, r = Q(t, s, n == null ? void 0 : n.debugClassNames, n == null ? void 0 : n.children), o = {
    sequenceHash: e,
    direction: t[1],
    children: [],
    debugClassNames: r
  };
  return E.getChildrenSequences(o.sequenceHash).reverse().forEach((a) => {
    const l = z(a, o);
    l && o.children.push(l);
  }), o.children.length || (o.rules = {}, o.debugClassNames.forEach(({
    className: a
  }) => {
    const l = E.getSequenceDetails(e);
    l && (o.slot = l.slotName, o.sourceURL = l.sourceURL);
    const f = E.getCSSRules().find((i) => i.includes(a));
    o.rules[a] = f;
  })), o;
}
function W(e) {
  const n = e.defaultView;
  if (!n || n.__GRIFFEL_DEVTOOLS__)
    return;
  const t = {
    getInfo: (s) => {
      const r = Array.from(s.classList).find((o) => o.startsWith(g));
      if (r !== void 0)
        return z(r);
    }
  };
  Object.defineProperty(n, "__GRIFFEL_DEVTOOLS__", {
    configurable: !1,
    enumerable: !1,
    get() {
      return t;
    }
  });
}
const x = /* @__PURE__ */ (() => {
  var e;
  try {
    return !!(typeof window < "u" && (!((e = window.sessionStorage) === null || e === void 0) && e.getItem("__GRIFFEL_DEVTOOLS__")));
  } catch {
    return !1;
  }
})();
function J(e) {
  if (!Array.isArray(e))
    return [e];
  if (process.env.NODE_ENV !== "production" && e.length > 2)
    throw new Error("CSS Bucket contains an entry with greater than 2 items, please report this to https://github.com/microsoft/griffel/issues");
  return e;
}
function Z(e, n, t) {
  const s = [];
  if (t[p] = n, e)
    for (const o in t)
      e.setAttribute(o, t[o]);
  function r(o) {
    return e != null && e.sheet ? e.sheet.insertRule(o, e.sheet.cssRules.length) : s.push(o);
  }
  return {
    elementAttributes: t,
    insertRule: r,
    element: e,
    bucketName: n,
    cssRules() {
      return e != null && e.sheet ? Array.from(e.sheet.cssRules).map((o) => o.cssText) : s;
    }
  };
}
const Y = [
  // reset styles
  "r",
  // catch-all
  "d",
  // link
  "l",
  // visited
  "v",
  // focus-within
  "w",
  // focus
  "f",
  // focus-visible
  "i",
  // hover
  "h",
  // active
  "a",
  // at rules for reset styles
  "s",
  // keyframes
  "k",
  // at-rules
  "t",
  // @media rules
  "m",
  // @container rules
  "c"
], L = /* @__PURE__ */ Y.reduce((e, n, t) => (e[n] = t, e), {});
function ee(e, n, t, s, r = {}) {
  const o = e === "m", c = o ? e + r.m : e;
  if (!s.stylesheets[c]) {
    const a = n && n.createElement("style"), l = Z(a, e, {
      ...s.styleElementAttributes,
      ...o && {
        media: r.m
      }
    });
    s.stylesheets[c] = l, n && a && n.head.insertBefore(a, ne(n, t, e, s, r));
  }
  return s.stylesheets[c];
}
function ne(e, n, t, s, r) {
  const o = L[t];
  let c = (i) => o - L[i.getAttribute(p)], a = e.head.querySelectorAll(`[${p}]`);
  if (t === "m" && r) {
    const i = e.head.querySelectorAll(`[${p}="${t}"]`);
    i.length && (a = i, c = (u) => s.compareMediaQueries(r.m, u.media));
  }
  const l = a.length;
  let f = l - 1;
  for (; f >= 0; ) {
    const i = a.item(f);
    if (c(i) > 0)
      return i.nextSibling;
    f--;
  }
  return l > 0 ? a.item(0) : n ? n.nextSibling : null;
}
const te = /* @__PURE__ */ ["-moz-placeholder", "-moz-focus-inner", "-moz-focusring", "-ms-input-placeholder", "-moz-read-write", "-moz-read-only"].join("|"), se = /* @__PURE__ */ new RegExp(`:(${te})`);
function $(e, n) {
  try {
    e.insertRule(n);
  } catch (t) {
    process.env.NODE_ENV !== "production" && !se.test(n) && console.error(`There was a problem inserting the following rule: "${n}"`, t);
  }
}
let oe = 0;
const re = (e, n) => e < n ? -1 : e > n ? 1 : 0;
function ie(e = typeof document > "u" ? void 0 : document, n = {}) {
  const {
    unstable_filterCSSRule: t,
    insertionPoint: s,
    styleElementAttributes: r,
    compareMediaQueries: o = re
  } = n, c = {
    insertionCache: {},
    stylesheets: {},
    styleElementAttributes: Object.freeze(r),
    compareMediaQueries: o,
    id: `d${oe++}`,
    insertCSSRules(a) {
      for (const l in a) {
        const f = a[l];
        for (let i = 0, u = f.length; i < u; i++) {
          const [d, m] = J(f[i]), w = ee(l, e, s || null, c, m);
          c.insertionCache[d] || (c.insertionCache[d] = l, process.env.NODE_ENV !== "production" && x && E.addCSSRule(d), t ? t(d) && $(w, d) : $(w, d));
        }
      }
    }
  };
  return e && process.env.NODE_ENV !== "production" && x && W(e), c;
}
const O = "<unknown>";
function ce(e) {
  return fe(e) || me(e) || Se(e);
}
const le = /^\s*at (.*?) ?\(((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/|[a-z]:\\|\\\\).*?)?\)?\s*$/i, ae = /^\s*at ()((?:file|https?|blob|chrome-extension|native|eval|webpack|<anonymous>|\/|[a-z]:\\|\\\\).*?)\s*$/i, ue = /\((\S*)\)/;
function fe(e) {
  const n = le.exec(e) || ae.exec(e);
  if (!n)
    return null;
  let t = n[2];
  const s = t && t.indexOf("native") === 0, r = t && t.indexOf("eval") === 0, o = ue.exec(t);
  return r && o != null && (t = o[1]), {
    loc: s ? null : n[2],
    name: n[1] || O
  };
}
const de = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|\[native).*?|[^@]*bundle)\s*$/i, he = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
function me(e) {
  const n = de.exec(e);
  if (!n)
    return null;
  let t = n[3];
  const s = t && t.indexOf(" > eval") > -1, r = he.exec(t);
  return s && r != null && (t = r[1]), {
    loc: n[3],
    name: n[1] || O
  };
}
const ge = /^\s*(?:([^@]*)(?:\((.*?)\))?@)?(\S.*?)\s*$/i;
function Se(e) {
  const n = ge.exec(e);
  return n ? {
    loc: n[3],
    name: n[1] || O
  } : null;
}
function Ee() {
  const e = String(new Error().stack).split(`
`), n = Ce(e);
  if (n === void 0)
    return;
  const t = ce(n);
  return t == null ? void 0 : t.loc;
}
function Ce(e) {
  for (let n = e.length - 1; n >= 0; --n)
    if (e[n].includes("at getSourceURLfromError"))
      return e[n + 3];
}
const pe = () => {
  const e = {};
  return function(t, s) {
    e[t.id] === void 0 && (t.insertCSSRules(s), e[t.id] = !0);
  };
};
function _e(e, n, t = pe) {
  const s = t();
  let r = null, o = null, c;
  process.env.NODE_ENV !== "production" && x && (c = Ee());
  function a(l) {
    const {
      dir: f,
      renderer: i
    } = l, u = f === "ltr";
    u ? r === null && (r = I(e, f)) : o === null && (o = I(e, f)), s(i, n);
    const d = u ? r : o;
    return process.env.NODE_ENV !== "production" && x && E.addSequenceDetails(d, c), d;
  }
  return a;
}
function xe() {
  return typeof window < "u" && !!(window.document && window.document.createElement);
}
const q = (
  // @ts-expect-error Hack to make sure that `useInsertionEffect` will not cause bundling issues in older React versions
  // eslint-disable-next-line no-useless-concat
  h["useInsertionEffect"] ? h["useInsertionEffect"] : void 0
), ve = () => {
  const e = {};
  return function(t, s) {
    if (q && xe()) {
      q(() => {
        t.insertCSSRules(s);
      }, [t, s]);
      return;
    }
    e[t.id] === void 0 && (t.insertCSSRules(s), e[t.id] = !0);
  };
}, Re = /* @__PURE__ */ h.createContext(/* @__PURE__ */ ie());
function Ne() {
  return h.useContext(Re);
}
const ye = /* @__PURE__ */ h.createContext("ltr");
function be() {
  return h.useContext(ye);
}
function Oe(e, n) {
  const t = _e(e, n, ve);
  return function() {
    const r = be(), o = Ne();
    return t({
      dir: r,
      renderer: o
    });
  };
}
const we = Oe({
  root: {
    mc9l5x: "f1w7gpdv",
    Bg96gwp: "fez10in",
    ycbfsm: "fg4l7m0"
  },
  rtl: {
    Bz10aip: "f13rod7r"
  }
}, {
  d: [".f1w7gpdv{display:inline;}", ".fez10in{line-height:0;}", ".f13rod7r{-webkit-transform:scaleX(-1);-moz-transform:scaleX(-1);-ms-transform:scaleX(-1);transform:scaleX(-1);}"],
  t: ["@media (forced-colors: active){.fg4l7m0{forced-color-adjust:auto;}}"]
}), De = (e, n) => {
  const {
    title: t,
    primaryFill: s = "currentColor",
    ...r
  } = e, o = {
    ...r,
    title: void 0,
    fill: s
  }, c = we(), a = M();
  return o.className = K(c.root, (n == null ? void 0 : n.flipInRtl) && (a == null ? void 0 : a.textDirection) === "rtl" && c.rtl, o.className), t && (o["aria-label"] = t), !o["aria-label"] && !o["aria-labelledby"] ? o["aria-hidden"] = !0 : o.role = "img", o;
}, Ie = (e, n, t, s) => {
  const r = n === "1em" ? "20" : n, o = h.forwardRef((c, a) => {
    const l = {
      ...De(c, {
        flipInRtl: s == null ? void 0 : s.flipInRtl
      }),
      ref: a,
      width: n,
      height: n,
      viewBox: `0 0 ${r} ${r}`,
      xmlns: "http://www.w3.org/2000/svg"
    };
    return h.createElement("svg", l, ...t.map((f) => h.createElement("path", {
      d: f,
      fill: l.fill
    })));
  });
  return o.displayName = e, o;
};
export {
  Ie as c
};
