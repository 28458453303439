import markerA from "./markerA.svg";
import markerB from "./markerB.svg";
import current from "./current.svg";
import pointer from "./pointer.svg";
import pointerGreen from "./pointer-green.svg";
import pointerGrey from "./pointer-grey.svg";
import green from "./green-plain.svg";
import pin from "./pin.svg";
import celltower from "./celltower.svg";
import radioIndoor from "./Radio-Indoor-Icon.svg";
import connectedDevices from "./connected-devices.svg";
import locationMapMarker from "./location-map-marker.svg";
import networkUtilization from "./network-utilization.svg";
import loginPageLogo from "./login_page_logo.svg";
import dataBarAdd from "./data-bar-vertical-add.svg";

export const asset = {
  markerA,
  markerB,
  currentLocation: current,
  pointer,
  pin,
  radioGreen: pointerGreen,
  radioGrey: pointerGrey,
  green,
  celltower,
  radioIndoor,
  connectedDevices,
  locationMapMarker,
  networkUtilization,
  loginPageLogo,
  dataBarAdd,
};
