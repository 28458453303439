import { jsx as i } from "react/jsx-runtime";
import { Button as p } from "@mui/material";
function c(r) {
  const {
    btype: t = "primary",
    width: e,
    height: n,
    sx: o,
    children: a,
    ...s
  } = r;
  return /* @__PURE__ */ i(
    p,
    {
      sx: [{ width: e, height: n }, d[t], ...Array.isArray(o) ? o : [o]],
      ...s,
      children: a
    }
  );
}
const d = {
  primary: (r) => ({
    borderColor: `${r.palette.grey[200]}`,
    borderRadius: 2,
    height: r.spacing(10),
    padding: r.spacing(0, 2.5),
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  }),
  secondary: (r) => ({
    borderColor: `${r.palette.grey[200]}`
  })
};
export {
  c as Button
};
