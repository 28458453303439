import { jsx as o, Fragment as c, jsxs as r } from "react/jsx-runtime";
import m from "../../../assets/icons/UserIcon.js";
import { Menu as s, Box as p, Grid as g, Typography as a, MenuItem as h } from "@mui/material";
const T = ({ profile: i, menuOptions: n, anchorEl: x, setAnchorEl: l }) => /* @__PURE__ */ o(c, { children: /* @__PURE__ */ o(s, { id: "simple-menu", anchorEl: x, open: !!x, sx: e.container, onClose: () => l(null), children: /* @__PURE__ */ r(p, { sx: e.popupBox, children: [
  /* @__PURE__ */ r(g, { container: !0, item: !0, sx: e.avatarContainer, children: [
    /* @__PURE__ */ o(m, { size: 40, color: "#616161" }),
    /* @__PURE__ */ r(p, { children: [
      /* @__PURE__ */ o(a, { sx: e.nameText, children: i.name }),
      /* @__PURE__ */ r(a, { sx: e.roleText, children: [
        "Role :",
        " ",
        i.role
      ] }),
      /* @__PURE__ */ o(a, { sx: e.emailText, children: i.email })
    ] })
  ] }),
  n == null ? void 0 : n.map((t, d) => /* @__PURE__ */ r(h, { sx: { ...e.menuItem, ...t.style }, onClick: t.onClick, children: [
    t.icon,
    t.text
  ] }, d))
] }) }) }), e = {
  container: {
    "& .MuiPaper-root": {
      borderRadius: "8px"
    }
  },
  menuItem: {
    borderTop: "1px solid #EFEFEF",
    padding: "6px 8px",
    gap: "8px",
    fontSize: "12px",
    color: "#374151",
    "& svg": {
      width: "16px",
      height: "16px",
      color: "rgb(0, 0, 0, 0.6)"
    },
    "&:hover": {
      borderRadius: "4px"
    }
  },
  popupBox: {
    padding: "16px",
    paddingTop: "10px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column"
  },
  avatarContainer: {
    minWidth: "250px",
    alignItems: "center",
    gap: "16px",
    background: "#F7F7F7",
    padding: "8px",
    marginBottom: "22px",
    borderRadius: "8px",
    flexWrap: "nowrap",
    fontFamily: "Roboto, sans-serif"
  },
  nameText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#141414"
  },
  roleText: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#878787",
    textTransform: "capitalize"
  },
  emailText: {
    fontSize: "12px",
    color: "#878787"
  },
  logoutItem: {
    color: "#D32F2F !important",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      width: "16px",
      height: "16px",
      color: "#DF3D2D"
    }
  }
};
export {
  T as default
};
