/**
 * Copyright Highway9 Networks Inc.
 */
import { Route, Switch } from "react-router-dom";
import {
  AUDITLOGS,
  DASHBOARD,
  DEVICES,
  EDGES,
  NETWORKS,
  RADIOS,
  SETTINGS,
  SIMULATORS,
  VMZ,
  HARDWARE_INVENTORY,
  PROFILES,
} from "../constants/routes";
import { Suspense, lazy, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import onAppStart from "./onAppStart";
import Layout from "./Layout";

const Dashboard = lazy(() => import("../views/dashboard-new/Dashboard"));
const RadiosPanelNew = lazy(() => import("../views/radios/RadiosPanelNew"));
const EdgesPanel = lazy(() => import("../views/edges/EdgesPanel"));
const SubscribersPanel = lazy(() => import("../views/subscribers/SubscribersPanel"));
const DnnPanel = lazy(() => import("../views/dnn/DnnPanel"));
const VMZone = lazy(() => import("../views/vmz"));
const Diagnostics = lazy(() => import("../views/diagnostics/SimulatorPanel"));
const AuditLog = lazy(() => import("../views/audit/AuditLog"));
const AdminPanel = lazy(() => import("../views/adminstration/AdminPanel"));
const NotImplemented = lazy(() => import("../views/NotImplemented/NotImplemented"));
const HardwareInventoryPanel = lazy(() => import("../views/hardwareInventory/HarwareInventoryPanel"));
const Profiles = lazy(() => import("../views/profiles/ProfilePanel"));

type Props = {};
const AppRoutes = (_props: Props) => {
  useEffect(() => {
    onAppStart();
  }, []);

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path={DASHBOARD} component={Dashboard} />
          <Route exact path={RADIOS} component={RadiosPanelNew} />
          <Route exact path={EDGES} component={EdgesPanel} />
          <Route exact path={DEVICES} component={SubscribersPanel} />
          <Route exact path={NETWORKS} component={DnnPanel} />
          <Route exact path={VMZ} component={VMZone} />
          <Route exact path={SIMULATORS} component={Diagnostics} />
          <Route exact path={SETTINGS} component={AdminPanel} />
          <Route exact path={PROFILES} component={Profiles} />
          <Route exact path={AUDITLOGS} component={AuditLog} />
          <Route exact path={HARDWARE_INVENTORY} component={HardwareInventoryPanel} />
          <Route component={NotImplemented} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default AppRoutes;

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
