import { jsx as m } from "react/jsx-runtime";
import { useTheme as n, Typography as y } from "@mui/material";
const T = {
  statistics1: "statistics1",
  // Statistics 
  statistics2: "statistics2",
  // Statistics 2
  heading1: "h1",
  // Heading 1
  heading2: "h2",
  // Heading 2
  bodyText: "h3",
  // Body Text
  title1: "h4",
  // Title 1
  smallBodyText1: "subtitle1",
  // Small Body Text 1
  smallBodyText2: "subtitle2",
  // Small Body Text 2
  smallText1: "body1",
  // Small Text
  smallText2: "body2"
  // Small Text
}, x = {
  statistics1: 700,
  statistics2: 700,
  heading1: 600,
  heading2: 600,
  bodyText: 400,
  title1: 600,
  smallBodyText1: 600,
  smallBodyText2: 400,
  smallText1: 400,
  smallText2: 600
}, c = {
  statistics1: "1.75rem",
  statistics2: "1rem",
  heading1: "1.125rem",
  heading2: "1rem",
  bodyText: "0.875rem",
  title1: "0.875rem",
  smallBodyText1: "0.75rem",
  smallBodyText2: "0.75rem",
  smallText1: "0.75rem",
  smallText2: "0.625rem"
};
function p(e) {
  const s = n(), {
    children: a,
    type: t = "bodyText",
    color: i = s.palette.grey[900],
    sx: o,
    ...l
  } = e, r = {
    fontWeight: x[t],
    fontSize: c[t],
    fontFamily: "Inter,sans-serif",
    letterSpacing: "unset"
  };
  return /* @__PURE__ */ m(
    y,
    {
      variant: T[t],
      color: i,
      sx: { ...r, ...o },
      ...l,
      children: a
    }
  );
}
export {
  p as Typography
};
