/**
 * Copyright Highway9 Networks Inc.
 */

import React, { useEffect, useState } from "react";
import { text } from "../../components/TextResources";
import { Alert, Grid, TextField } from "@mui/material";
import useErrorsAndWarnings from "../../hooks/useErrorsAndWarnings";
import ErrorsComponent from "../../components/ErrorsComponent";
import { useAppDispatch } from "~/store/hooks";
import { utilityActions } from "~/store/context/utilitySlice";
import timezones from "~/assets/timezones.json";
import { SearchSelector } from "@h9/ui-lib";
import moment from "moment";


export function GeneralDetails(props) {
  const dispatch = useAppDispatch();
  const { errors, warnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [authType, setAuthType] = useState([]);

  useEffect(() => {
    setName(props.user?.name);
    setEmail(props.user?.email);
    setAuthType(props.user?.authType);
    if (props.user?.timeZone)
    dispatch(utilityActions.setTimeZone(props.user?.timeZone));
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);
  // const [changePassword ,setChangePassword] = useState(false)

  // useEffect(()=>{
  //   if(user.oldPassword && user.newPassword && user.repeatPassword){
  //     if(user.newPassword !== user.repeatPassword){
  //       setIsValid(false)
  //       setErrorsAndWarnings([],[{message : "Password and repeat password does not match"}])
  //     }else{
  //       setIsValid(true)
  //       setErrorsAndWarnings([],[])
  //     }
  //   }else{
  //     setErrorsAndWarnings([],[{message : "Please enter all required fields"}])
  //     setIsValid(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[user])

  const onChangeHandler = (type, e) => {
    if (type === "name") {
      setName(e.target.value);
    } else if (type === "email") {
      setEmail(e.target.value);
    }
  };

  // const handleChangePassword =(e)=>{
  //     setChangePassword(e.target.checked)
  //     if(!e.target.checked){
  //         setUser({
  //             ...user,
  //             oldPassword: "",
  //             newPassword : "",
  //             repeatPassword : ""

  //         })
  //     }
  // }

  function getConsoleURL() {
    const url = window.location.hostname;

    const subdomain = url.split(".");

    if (subdomain[0] === "localhost") {
      return "http://console.localhost:3000";
    }
    // remove the first part of the subdomain and join the rest to get the console url with console. prefix
    subdomain.shift();
    return "https://console." + subdomain.join(".");
  }

  return (
    <Grid container>
      <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />

      <Alert severity="info" style={{ marginBottom: 10, width: '100%' }}>
        {text.passwordEditInfo} {getConsoleURL()}
      </Alert>

      <Grid item xs={12}>
        <TextField
          variant="standard"
          disabled
          label={text.userName}
          value={name}
          id="name"
          fullWidth
          required
          onChange={(e) => onChangeHandler("name", e)}
        ></TextField>
        <br />
        <br />
        <TextField
          variant="standard"
          disabled
          label={text.email}
          value={email}
          id="email"
          fullWidth
          required
          onChange={(e) => onChangeHandler("email", e)}
        ></TextField>
        <br />
        <br />
        <TextField
          variant="standard"
          disabled
          label={"Auth Type"}
          value={authType?.join(", ")}
          id="auth type"
          fullWidth
          required
          onChange={(e) => onChangeHandler("email", e)}
        ></TextField>
        <br />
        <br />

        <TimeZone timezone={props.timezone} onChange={props.changeTimezone} />

        {/* {authType?.includes("PASSWORD") &&
                        <Box mt={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked = {changePassword}
                                    onChange={handleChangePassword}
                                    name="changePassword"
                                    color="primary"
                                    />
                                }
                                label="Change Password"
                            />
                        </Box>
                    }
                    
                    {changePassword && 
                        <>
                        <Box mt={1}>
                        <TextField variant="standard" label={text.oldPassword} value={user.oldPassword} name="oldpassword" type="password" fullWidth required onChange={(e)=>onChangeHandler("oldPassword",e)}></TextField> 
                        </Box>
                        <Box mt={1}>
                        <TextField variant="standard" label={text.newPassword} value={user.newPassword} name="newpassowrd" type="password" onChange={(e)=>onChangeHandler("newPassword",e)} fullWidth required></TextField>
                        </Box>
                        <Box mt={1}>
                        <TextField variant="standard" label={text.repeatPassword} value={user.repeatPassword} name="repeatpassword" type="password" onChange={(e)=>onChangeHandler("repeatPassword",e)} fullWidth required></TextField> <br/> <br/>
                        </Box>
                        </>
                    } */}
      </Grid>
    </Grid>
  );
}


function TimeZone(props) {
  const timeZones = React.useMemo(() => {
    return Object.entries(timezones).map(([tz, name]) => {
      return { label: name, value: tz , group : "All" };
    });
  }, []);
  
  const systemTimeZone = React.useMemo(() => moment.tz.guess(), []);
  
  const current = React.useMemo(() => {
    return {...timeZones.find((zone) => zone.value === systemTimeZone) , group : "System", value: null}
  }, [timeZones, systemTimeZone]);

  return (
    <>
      <label style={{ fontSize : 10 }}>Timezone</label>
      <SearchSelector 
        value={timeZones.find((zone) => zone.value === (props.timezone || systemTimeZone) )}
        options={[current,  ...timeZones]}
        onChange={(zone) => props.onChange(zone?.value)}
        style={{ background : 'transparent', color : 'black' , border : '1px solid #ccc'}}
      />
    </>

  )

}