import { Router, Route, Switch } from "react-router-dom";
import { history } from "./helpers";
import { AuthRouter } from "./navigation/AuthRouter";
import { LoginPage } from "./navigation/LoginPage";
import { theme as newTheme } from "@h9/ui-lib";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import ErrorBoundary from "./components/ErrorBoundary";
import AppRoutes from "./navigation/AppRoutes";
import SnackbarX from "./components/shared/SnackbarX";
import "@h9/ui-lib/dist/assets/style.css";
import "./App.css";
import "./styles/flexStyles.css";
import { theme as theme } from "./styles/theme";
import { isRevampedDashboard } from "./helpers/utils";
import { useEffect, useState } from "react";

function App() {
  const [themeObj, setThemeObj] = useState(isRevampedDashboard() ? newTheme : theme);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isRevampedDashboard()) {
        setThemeObj(newTheme);
      } else {
        setThemeObj(theme);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <div className="App">
      <ErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themeObj}>
            <Router history={history}>
              <Switch>
                <Route path="/login" component={LoginPage} />
                <AuthRouter path="/" component={AppRoutes} />
              </Switch>
            </Router>
            <SnackbarX />
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
