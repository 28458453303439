import "./assets/style.css";
const e = "_value_ef2qs_3", s = "_menu_ef2qs_20", o = "_options_ef2qs_24", _ = "_input_ef2qs_41", t = "_error_ef2qs_69", n = "_disabled_ef2qs_73", p = "_group_ef2qs_82", r = "_option_ef2qs_24", c = "_selected_ef2qs_99", u = {
  value: e,
  menu: s,
  options: o,
  input: _,
  error: t,
  disabled: n,
  group: p,
  option: r,
  selected: c
};
export {
  u as s
};
