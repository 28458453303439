/**
 * Copyright Highway9 Networks Inc.
 */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { metricHelper } from "../../helpers/metricHelper";
import { dashboardService } from "../../services";
import { ServiceController } from "../../types/serviceController";
import { MetricsAggQuery } from "~/services/APIServices";

type PeakConnectionsData = {
  name: string;
  color: string;
  data: [number];
};

type initState = {
  serviceControllers: ServiceController[];
  currentController: ServiceController | null;
  metrics: {
    [key: string]: [number, number][];
  };
  peakConnectionsData: PeakConnectionsData[];
};

const initialState: initState = {
  serviceControllers: [],
  currentController: null,
  metrics: {},
  peakConnectionsData: [],
};

export const fetchServiceController = createAsyncThunk("dashboard/fetchServiceController", async () => {
  const controller = await dashboardService.getControllerSummary();
  return controller;
});

async function getMetrics({ startTime, endTime }: { startTime: number; endTime: number }) {
  try {
    console.log("radio_connected....");
    const params: MetricsAggQuery = {
      metrics: [
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "radio_overall_health",
        "devices_connected",
      ],
      interval: {
        startTime,
        endTime,
      },
      resolution: metricHelper.getResolution(startTime, endTime),
      queryKeys: [
        {
          key: "radio_overall_health_status",
          value: "UNKNOWN",
        },
        {
          key: "radio_overall_health_status",
          value: "GOOD",
        },
        {
          key: "radio_overall_health_status",
          value: "BAD",
        },
        {
          key: "radio_overall_health_status",
          value: "THIRDPARTY",
        },
        {
          key: "radio_overall_health_status",
          value: "PARTIALLY_GOOD",
        },
      ],
    };
    const data = await dashboardService.getMetrics(params);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const fetchDashboardMetrics = createAsyncThunk("dashboard/fetchDashboardMetrics", getMetrics);
const radioOverallHealthStatusOrder = ["UNKNOWN", "GOOD", "BAD", "THIRDPARTY", "PARTIALLY_GOOD"];
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPeakConnectionsData: (state, action: PayloadAction<PeakConnectionsData[]>) => {
      state.peakConnectionsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceController.fulfilled, (state, action) => {
      state.serviceControllers = action.payload;
      state.currentController = action.payload[0];
    });
    builder.addCase(fetchDashboardMetrics.fulfilled, (state, action) => {
      let x = 0;
      action.payload.forEach((itm: any) => {
        if (itm.metric === "radio_overall_health") {
          const fullMetricName = itm.metric + "_" + radioOverallHealthStatusOrder[x];
          state.metrics[fullMetricName] = itm.metricData || [];
          x++;
        } else {
          state.metrics[itm.metric] = itm.metricData || [];
        }
      });
    });
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const selectServiceControllers = (state: RootState) => state.dashboard.serviceControllers;
export const selectCurrentServiceController = (state: RootState) => state.dashboard.currentController;
export const DashboardMetrics = (state: RootState) => state.dashboard.metrics;

export const DashboardMetric = (metric: string) => (state: RootState) => state.dashboard.metrics[metric];
export const softwareVersion = (state: RootState) => state.dashboard.currentController?.release;
export const dashboardPeakConnectionsData = (state: RootState) => state.dashboard.peakConnectionsData;
