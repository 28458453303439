/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import React, { useEffect } from "react";
import SideDrawerNew, { DRAWER_WIDTH } from "./SideDrawerNew";
import { Grid } from "@h9/ui-lib";
import { authenticationService } from "~/services";
import { text } from "~/components/TextResources";
import { TopBarNew } from "./TopBar";
import { isRevampedDashboard } from "~/helpers/utils";
import { useAppSelector } from "~/store/hooks";
import { selectCurrentServiceController } from "~/store/context/dashboardSlice";

type Props = {
  children: React.ReactNode;
};

const styles = {
  main: {
    flexGrow: 1,
    maxWidth: `calc(100% - ${DRAWER_WIDTH}px)`,
    overflow: "auto",
    height: "100%",
  },
  container: {
    overflow: "auto",
    flexGrow: 1,
  },
};

const LayoutNew = (props: Props) => {
  const serviceInstanceDisplayName = useAppSelector(selectCurrentServiceController)?.displayName!;
  const serviceInstanceName = authenticationService?.currentUserValue?.serviceInstanceName ?? "";

  useEffect(() => {
    document.title = `${serviceInstanceDisplayName ? serviceInstanceDisplayName : serviceInstanceName} - ${text.title}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceInstanceDisplayName]);
  return (
    <Grid container height="100vh" flexDirection="column" flexWrap="nowrap" width="100%">
      <TopBarNew title={serviceInstanceDisplayName} subtitle={text.topBarSubtitle} />
      <Grid container item flexWrap="nowrap" flex={1} sx={styles.container}>
        <SideDrawerNew />
        <Grid item component="main" sx={{ ...styles.main, padding: isRevampedDashboard() ? null : "0 8px" }}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LayoutNew;
