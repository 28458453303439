import { jsx as r, jsxs as t } from "react/jsx-runtime";
import { Button as g } from "@mui/material";
import { AppBar as h } from "../../atoms/AppBar/AppBar.js";
import { Avatar as f } from "../../atoms/Avatar/Avatar.js";
import "react";
import { Divider as u } from "../../atoms/Divider/Divider.js";
import "../../../map-de2346a3.js";
import "../../../server.browser-e466af8c.js";
import { Grid as i } from "../../atoms/Grid/Grid.js";
/* empty css                      */import "../../../style.module-61e1e2b8.js";
import "../../DateTimeSelector/index.js";
import "../../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "moment-timezone";
import { Typography as o } from "../../atoms/Typography/Typography.js";
import { Toolbar as y } from "../../atoms/Toolbar/Toolbar.js";
import { Highway9LogoSmall as C } from "../../../assets/icons/Highway9LogoSmall.js";
import { c as F } from "../../../createFluentIcon-5dfeaf33.js";
import { C as B } from "../../../chunk-1-cf357621.js";
const v = /* @__PURE__ */ F("PersonCircleFilled", "1em", ["M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8 7a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm-.5 3h5c.83 0 1.5.67 1.5 1.5 0 1.12-.46 2.01-1.21 2.61-.74.6-1.74.89-2.79.89a4.43 4.43 0 0 1-2.79-.89A3.25 3.25 0 0 1 6 11.5c0-.83.67-1.5 1.5-1.5Z"]);
function O({
  children: n,
  subtitle: a,
  title: p,
  userName: l,
  profileCard: c,
  setAnchorEl: m = () => null,
  type: s = "primary"
}) {
  const d = (x) => {
    m(x.currentTarget);
  };
  return /* @__PURE__ */ r(
    h,
    {
      position: "static",
      sx: [e.appBar, I[s].appBar],
      children: /* @__PURE__ */ t(y, { sx: e.toolBar, children: [
        /* @__PURE__ */ t(i, { container: !0, sx: e.leftContainer, children: [
          /* @__PURE__ */ t(i, { sx: e.headings, children: [
            /* @__PURE__ */ r(i, { sx: e.logo, children: /* @__PURE__ */ r(C, {}) }),
            /* @__PURE__ */ r(
              u,
              {
                type: "light",
                variant: "middle",
                flexItem: !0,
                orientation: "vertical",
                sx: e.divider
              }
            ),
            /* @__PURE__ */ r(i, { sx: e.container, children: /* @__PURE__ */ t(
              i,
              {
                container: !0,
                item: !0,
                alignItems: "center",
                sx: e.titleContainer,
                children: [
                  /* @__PURE__ */ r(o, { type: "heading1", color: "#FFFFFF", children: p }),
                  /* @__PURE__ */ r(
                    o,
                    {
                      type: "smallText1",
                      color: "#ABD9CE",
                      sx: { fontSize: "1rem" },
                      children: a
                    }
                  )
                ]
              }
            ) })
          ] }),
          n
        ] }),
        /* @__PURE__ */ t(
          g,
          {
            variant: "text",
            sx: e.user,
            endIcon: /* @__PURE__ */ r(B, { color: "white", fontSize: "16" }),
            onClick: d,
            children: [
              /* @__PURE__ */ r(
                f,
                {
                  width: 24,
                  height: 24,
                  sx: { backgroundColor: "transparent" },
                  children: /* @__PURE__ */ r(v, { fontSize: 24, opacity: 0.7 })
                }
              ),
              /* @__PURE__ */ r(
                o,
                {
                  type: "smallBodyText1",
                  color: "#FFFFFF",
                  sx: { letterSpacing: "0.23px" },
                  children: l
                }
              )
            ]
          }
        ),
        c
      ] })
    }
  );
}
const e = {
  appBar: () => ({
    zIndex: 1201,
    width: "100%",
    justifyContent: "center",
    paddingLeft: 0
  }),
  container: () => ({
    gap: "8px",
    paddingLeft: "8px"
  }),
  leftContainer: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  logo: () => ({
    fontSize: "1.5rem",
    padding: "10px 14px",
    display: "flex",
    alignItems: "center"
  }),
  toolBar: {
    minHeight: "0 !important",
    gap: {
      xs: "8px",
      sm: "16px"
    },
    padding: "0 !important"
  },
  divider: () => ({
    margin: "10px 0"
  }),
  titleContainer: {
    alignItems: "center",
    gap: "8px"
  },
  user: {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    background: "#1A5F4E",
    height: "100%",
    padding: "0 24px",
    textTransform: "unset",
    borderRadius: 0,
    ":hover": {
      backgroundColor: "#277A66"
    }
  },
  headings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}, I = {
  primary: {
    appBar: {
      backgroundColor: "#226A58"
    }
  },
  secondary: {
    appBar: {}
  }
};
export {
  O as TopBar
};
