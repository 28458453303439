/**
 * Copyright 2021-2022 Highway9 Networks Inc.
 */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { siteService } from "../../services/site-service";
import { ISite } from "../../types/site";
import { SiteTree } from "../../views/radios/Site";

type initState = {
  open: boolean;
  siteEditFromExcelImport: boolean;
  edit: boolean;
  current: ISite | null;
  editExcelData?: ISite | null;
  data: ISite[];
  siteNZone: SiteTree[];
  error: string | null | undefined;
  fetchDone: boolean;
  expanded: string[];
  dashboardSelectedSite: ISite | null;
};

const initialState: initState = {
  open: false,
  siteEditFromExcelImport: false,
  edit: false,
  current: null,
  editExcelData: null,
  data: [],
  siteNZone: [],
  error: null,
  fetchDone: false,
  expanded: [],
  dashboardSelectedSite: null
};

export const fetchSites = createAsyncThunk("site/fetchSites", async () => {
  const _sites = (await siteService.getSites()).sort((a, b) => a.name.localeCompare(b.name));
  return _sites;
});

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
    setSiteEditFromExcelImport: (state, action: PayloadAction<boolean>) => {
      state.siteEditFromExcelImport = action.payload;
    },
    setEdit: (state, action: PayloadAction<boolean>) => {
      state.edit = action.payload;
    },
    setValues: (state, action: PayloadAction<ISite | null>) => {
      state.current = action.payload;
    },
    setData: (state, action: PayloadAction<ISite[]>) => {
      state.data = action.payload;
    },
    updateData: (state, action: PayloadAction<ISite>) => {
      const index = state.data.findIndex((item) => item.id === action.payload.id);
      state.data[index] = action.payload;
    },
    addData: (state, action: PayloadAction<ISite>) => {
      state.data.push(action.payload);
    },
    delete: (state, action: PayloadAction<string>) => {
      const index = state.data.findIndex((item) => item.id === action.payload);
      state.data = state.data.filter((_, i) => i !== index);
    },
    setSiteNZone: (state, action: PayloadAction<any>) => {
      state.siteNZone = action.payload;
    },
    setFetchDone: (state, action: PayloadAction<boolean>) => {
      state.fetchDone = action.payload;
    },
    setExpanded: (state, action: PayloadAction<string[]>) => {
      state.expanded = action.payload;
    },
    setDashboardSelectedSite: (state, action: PayloadAction<ISite | null>) => {
      state.dashboardSelectedSite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        console.log(action);
        state.error = action.error.message;
      });
  },
});

export const siteActions = siteSlice.actions;
export default siteSlice.reducer;
export const siteState = (state: RootState) => state.site.current;
export const siteData = (state: RootState) => state.site.data;
export const siteCount = (state: RootState) => state.site.data.length;
export const siteNZone = (state: RootState) => state.site.siteNZone;
export const siteOpen = (state: RootState) => state.site.open;
export const siteEditFromExcelImport = (state: RootState) => state.site.siteEditFromExcelImport;
export const siteEdit = (state: RootState) => state.site.edit;
export const fetchDone = (state: RootState) => state.site.fetchDone;
export const siteExpanded = (state: RootState) => state.site.expanded;
export const siteCurrentDashboard = (state: RootState) => state.site.dashboardSelectedSite;
