import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "~/store";
import { defaultEdge, Edge, EdgeIpsecConfig } from "~/types/edge";

type initState = {
  editState: Edge;
  isSecondaryAAA: boolean;

};

const initialState: initState = {
  editState: defaultEdge,
  isSecondaryAAA: false,
};

const edgeEditSlice = createSlice({
  name: "edgeEdit",
  initialState,
  reducers: {
    setEditState: (state, action: PayloadAction<Edge>) => {
      state.editState = action.payload;
    },

    // general
    setName: (state, action: PayloadAction<string>) => {
      state.editState.name = action.payload;
    },

    setEnableSecurityGateway: (state, action: PayloadAction<boolean>) => {
      state.editState.enableSecurityGateway = action.payload;
    },

    // network
    setNetworkMode: (state, action: PayloadAction<string>) => {
      state.editState.networkMode = action.payload;
    },
    addNetwork: (state) => {
      state.editState.networks = [
        ...state.editState.networks,
        {
          dnnId: "",
          vlanId: null,
          gatewayIp: null,
          subnet: "192.168.128.0/24",
          ipAllocationMode: "DHCP",
        },
      ];
    },

    setPrimaryDNS: (state, action: PayloadAction<string>) => {
      if (state.editState.managementNetwork) {
        if (action.payload.length === 0) {
          state.editState.managementNetwork.dns.pop();
        } else {
          state.editState.managementNetwork.dns[0] = action.payload;
        }
      } else {
        state.editState.managementNetwork = {
          dns: action.payload?.length ? [action.payload] : [],
        };
      }
    },
    setSecondaryDNS: (state, action: PayloadAction<string>) => {
      if (state.editState.managementNetwork) {
        if (action.payload.length === 0) {
          state.editState.managementNetwork.dns.pop();
        } else {
          state.editState.managementNetwork.dns[1] = action.payload;
        }
      }
    },

    setDataNetworkPublicIp: (state, action: PayloadAction<string>) => {
      state.editState.dataNetworkPublicIp = action.payload;
    },
    setRadioNetworkPublicIp: (state, action: PayloadAction<string>) => {
      state.editState.radioNetworkPublicIp = action.payload;
    },
    setGlobalTcpMss: (state, action: PayloadAction<number>) => {
      state.editState.globalTcpMss = action.payload;
    },
    setLogLevelType:(state,action: PayloadAction<string>)=>{
      state.editState.logLevelType=action.payload
    },
    deleteNetwork: (state, action: PayloadAction<{ index: number }>) => {
      state.editState.networks = state.editState.networks.filter((_, i) => action.payload.index !== i);
    },

    // network card

    setVlanId: (state, action: PayloadAction<{ value: number; index: number }>) => {
      state.editState.networks[action.payload.index].vlanId = action.payload.value;
    },
    setSubnet: (state, action: PayloadAction<{ value: string; index: number }>) => {
      state.editState.networks[action.payload.index].subnet = action.payload.value;
    },
    setDnn: (state, action: PayloadAction<{ value: string; index: number }>) => {
      state.editState.networks[action.payload.index].dnnId = action.payload.value;
    },
    setGatewayIP: (state, action: PayloadAction<{ value: string; index: number }>) => {
      state.editState.networks[action.payload.index].gatewayIp = action.payload.value;
    },
    setGlobalTCPMSS: (state, action: PayloadAction<number>) => {
      state.editState.globalTcpMss = action.payload;
    },

    // monitoring

    setSNMPMonitoring: (state, action: PayloadAction<{ id: string; default?: boolean }>) => {
      if (state.editState.monitoringSystemConfig) {
        state.editState.monitoringSystemConfig.id = action.payload.id;
        state.editState.monitoringSystemConfig.useDefault = action.payload.default ?? false;
      } else {
        state.editState.monitoringSystemConfig = {
          id: action.payload.id,
          useDefault: action.payload.default ?? false,
        };
      }
    },

    /// AAA
    setAuthzEnabled: (state, action: PayloadAction<boolean>) => {
      if (state.editState.authZConfig) {
        state.editState.authZConfig.authzEnabled = action.payload;
      } else {
        state.editState.authZConfig = {
          authzEnabled: action.payload,
          aaaServerProfiles: [],
          isAuthzConfigChanged: false,
        };
      }
    },

    setAAAProfile: (state, action: PayloadAction<string>) => {
      if (state.editState.authZConfig) {
        state.editState.authZConfig.aaaServerProfiles[0] = {
          aaaServerProfileId: action.payload,
        };
      }
    },
    setIsEnabledSecondaryAAAServer: (state, action: PayloadAction<boolean>) => {
      state.isSecondaryAAA = action.payload;
    },

    setSecondaryAAAProfile: (state, action: PayloadAction<string>) => {
      if (state.editState.authZConfig) {
        state.editState.authZConfig.aaaServerProfiles[1] = {
          aaaServerProfileId: action.payload,
        };
      }
    },

    // cert config
    setCertConfig: (state, action: PayloadAction<{ value: EdgeIpsecConfig; index: number }>) => {
      if (state.editState.edgeIpsecConfig) {
        state.editState.edgeIpsecConfig[action.payload.index] = action.payload.value;
      } else {
        state.editState.edgeIpsecConfig = [action.payload.value];
      }
    },

    addNewCertConfig: (state) => {
      const defaultCert: EdgeIpsecConfig = {
        edgeIpsecCert: "",
        edgeIpsecCertFileName: "",
        edgeIpsecKey: "",
        edgeIpsecKeyFileName: "",
      };
      if (state.editState.edgeIpsecConfig) {
        state.editState.edgeIpsecConfig.push(defaultCert);
      } else {
        state.editState.edgeIpsecConfig = [defaultCert];
      }
    },

    // setCertName: (state, action: PayloadAction<{ value: string; index: number }>) => {
    //   if (state.editState.edgeIpsecConfig) {
    //     state.editState.edgeIpsecConfig[action.payload.index].name = action.payload.value;
    //   }
    // },

    // setCertRightId: (state, action: PayloadAction<{ value: string; index: number }>) => {
    //   if (state.editState.edgeIpsecConfig) {
    //     state.editState.edgeIpsecConfig[action.payload.index].rightId = action.payload.value;
    //   }
    // },

    setIPSECcertificate: (
      state,
      action: PayloadAction<{
        value: {
          name: string;
          content: string;
        };
        index: number;
      }>
    ) => {
      if (state.editState.edgeIpsecConfig) {
        state.editState.edgeIpsecConfig[action.payload.index].edgeIpsecCert = action.payload.value.content;
        state.editState.edgeIpsecConfig[action.payload.index].edgeIpsecCertFileName = action.payload.value.name;
      }
    },

    setIPSECkey: (
      state,
      action: PayloadAction<{
        value: {
          name: string;
          content: string;
        };
        index: number;
      }>
    ) => {
      if (state.editState.edgeIpsecConfig) {
        state.editState.edgeIpsecConfig[action.payload.index].edgeIpsecKey = action.payload.value.content;
        state.editState.edgeIpsecConfig[action.payload.index].edgeIpsecKeyFileName = action.payload.value.name;
      }
    },

    removeCertConfig: (state, action: PayloadAction<number>) => {
      state.editState.edgeIpsecConfig = state.editState.edgeIpsecConfig?.filter((_, i) => action.payload !== i);
    },

    // setEnableTrunkMode: (state, action: PayloadAction<boolean>) => {
    //   state.editState.enableTrunkMode = action.payload;
    // },
    // setEdgeLocationType: (state, action: PayloadAction<string>) => {
    //     state.editState.location.locationType = action.payload;
    // },
    setLocationName: (state, action: PayloadAction<string>) => {
      state.editState.location.name = action.payload;
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      state.editState.location.latitude = action.payload;
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      state.editState.location.longitude = action.payload;
    },
  },
});

export const edgeEditActions = edgeEditSlice.actions;
export default edgeEditSlice.reducer;
export const EdgeEditState = (state: RootState) => state.edgeEdit.editState;
export const EdgeEditSecondaryAAA = (state: RootState) => state.edgeEdit.isSecondaryAAA;
// export const EdgeEditIsValid = (state: RootState) => state.edgeEdit.isValid;
