import { jsx as a } from "react/jsx-runtime";
import { Divider as l } from "@mui/material";
function y(r) {
  const { type: t = "dark", width: o, height: i, sx: e, children: s, ...d } = r;
  return /* @__PURE__ */ a(
    l,
    {
      sx: [{ width: o, height: i }, p[t], ...Array.isArray(e) ? e : [e]],
      ...d,
      children: s
    }
  );
}
const p = {
  light: (r) => ({
    borderColor: `${r.palette.grey[200]}1A`
  }),
  dark: (r) => ({
    borderColor: `${r.palette.grey[600]}4D`
  })
};
export {
  y as Divider
};
