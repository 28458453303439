import { CARRIER_MODE, COLOR, UNKNOWN, GOOD, PARTIALLY_GOOD } from "../../../constants";
import Radio, { RadioX } from "../../../types/radio";
import { radioLog, getRadioStatus } from "../radioHelper";

export const getEdgeGaugeData = (data: Radio[]) => {
  const status = {
    connected: 0,
    disconnected: 0,
    unknown: 0,
    partiallyConnected: 0,
    thirdParty: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const state = getEdgeStatus(item);

    switch (state) {
      case "connected":
        status.connected++;
        break;
      case "disconnected":
        status.disconnected++;
        break;
      case "unknown":
        status.unknown++;
        break;
      case "partiallyConnected":
        status.partiallyConnected++;
        break;
      case "thirdParty":
        status.thirdParty++;
        break;
      default:
        break;
    }
  }

  return status;
};

export const getEdgeStatus = (radio: Radio) => {
  const MMEstatus = radio.runtimeInfo?.mmeStatus;


  // if is third party
  if (!radio.isManaged) {
    return "thirdParty";
  }
  // if is unknown
  if (!radio.hasOwnProperty("serialNumber") || MMEstatus?.length === 0) {
    return "unknown";
  }

  let connectedCount = 0;
  const count = {
    private: 0,
    mocn: 0,
    connectedPrivate: 0,
    connectedMocn: 0,
  }
  MMEstatus?.forEach((_mme) => {
    const status = _mme.status;
    const edge = radio.edgeConfig?.find((edge) => edge.mmeIp === _mme.ip);
    const edgeType = radio.edges?.find((e) => e.edge.id === edge?.id)?.edge.type;

    if (edgeType === "PRIVATE_CELLULAR") {
      count.private += 1;
    } else if (edgeType === "CARRIER_GATEWAY") {
      count.mocn += 1;
    }

    if (status && (edge || radio.isPartnerRadio)) {
      if (edgeType === "PRIVATE_CELLULAR") {
        count.connectedPrivate += 1;
      } else if (edgeType === "CARRIER_GATEWAY") {
        count.connectedMocn += 1;
      }
      connectedCount += 1;
    }
  });

  // if is connected
  if (connectedCount === MMEstatus?.length) {
    return "connected";
  }

  // if is disconnected
  else if ((count.connectedPrivate === 0 && count.mocn != MMEstatus?.length) || (count.connectedMocn === 0 && count.private != MMEstatus?.length)) {
    return "disconnected";
  }

  // if is partially connected
  else {
    return "partiallyConnected";
  }
};

export const getRFGaugeData = (data: Radio[]) => {
  const status = {
    good: 0,
    bad: 0,
    needAttention: 0,
    off: 0,
    unknown: 0,
    thirdParty: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const state = getRFStatus(item);

    switch (state) {
      case "good":
        status.good++;
        break;
      case "bad":
        status.bad++;
        break;
      case "needAttention":
        status.needAttention++;
        break;
      case "off":
        status.off++;
        break;
      case "unknown":
        status.unknown++;
        break;
      case "thirdParty":
        status.thirdParty++;
        break;
      default:
        break;
    }
  }

  return status;
};

export const getRFStatus = (radio: Radio) => {
  const cells = radio.runtimeInfo?.cellParameters;
  const configCells = radio.cellParameters;
  const isDualCarrier = radio.carrierMode === CARRIER_MODE.DUAL_CARRIER;

  // if is third party
  if (!radio.isManaged) {
    return "thirdParty";
  }

  // if is unknown
  if (!radio.hasOwnProperty("serialNumber") || cells?.length === 0) {
    return "unknown";
  }

  let onCount = 0;
  const isBad = new Set<boolean>([]);
  const isRFmatched = new Set<boolean>([]);
  cells?.forEach((_cell, i) => {
    const RFenabled = _cell.rfEnabled;
    const configRFenabled = configCells?.[i]?.rfEnabled;

    if (RFenabled && configRFenabled) {
      onCount += 1;
    }

    if (RFenabled !== configRFenabled) {
      isBad.add(true);
      isRFmatched.add(false);
    } else {
      isBad.add(false);
      isRFmatched.add(true);
    }
  });

  // console.log(radio.name,  "isBad", isBad)

  // if is bad
  // IF config and runtime is not matched for all cell
  if (!isBad.has(false)) return "bad";

  // if is good / on
  if (onCount === cells?.length) {
    return "good";
  }

  // if is off
  else if (onCount === 0 || !isRFmatched.has(false)) {
    return "off";
  }
  // if is it is DUAL CARRIER and partially on
  else if (isDualCarrier) {
    return "bad";
  }
  // if is partially on / need attention
  else {
    return "needAttention";
  }
};

export const getVMCGaugeData = (data: Radio[]) => {
  const status = {
    active: 0,
    inactive: 0,
    unknown: 0,
    thirdParty: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const state = getVMCStatus(item);

    switch (state) {
      case "active":
        status.active++;
        break;
      case "inactive":
        status.inactive++;
        break;
      case "unknown":
        status.unknown++;
        break;
      case "thirdParty":
        status.thirdParty++;
        break;
      default:
        break;
    }
  }

  return status;
};

export const getVMCStatus = (radio: Radio) => {
  const vmcStatus = radio.runtimeInfo?.connected;
  const serial = radio.serialNumber;

  // if is third party
  if (!radio.isManaged) {
    return "thirdParty";
  }
  // if there is no serial number
  if (!serial) return "unknown";
  // if is connected
  if (vmcStatus) {
    return "active";
  }

  // if is disconnected
  else {
    return "inactive";
  }
};

const MINS = 60;
const HOUR = 60 * 60;
const DAY = 24 * 60 * 60;
const TEN_DAYS = 10 * DAY;
const THIRTY_DAYS = 30 * DAY;
const NINETY_DAYS = 90 * DAY;

export const getDeviceRunningStatus = (item: Radio) => {
  if (!item.runtimeInfo?.uptime) return;

  const tokenRegex = new RegExp("([0-9]+)d ([0-9]+)h ([0-9]+)m ([0-9]+)s", "g");
  const match = tokenRegex.exec(item.runtimeInfo.uptime);

  if (!match) {
    radioLog("error", "uptime regex failed", item.runtimeInfo.uptime);
    return;
  }
  // radioLog(match ,  match[0], match[1], match.length);

  const days = parseInt(match[1]);
  const hours = parseInt(match[2]);
  const minutes = parseInt(match[3]);
  const seconds = parseInt(match[4]);

  const uptime = days * DAY + hours * HOUR + minutes * MINS + seconds; // in seconds

  if (uptime < TEN_DAYS) {
    return 1;
  } else if (uptime < THIRTY_DAYS) {
    return 2;
  } else if (uptime < NINETY_DAYS) {
    return 3;
  } else {
    return 4;
  }
};

export const getDeviceRunningData = (data: Radio[]) => {
  const bar = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const state = getDeviceRunningStatus(item);

    switch (state) {
      case 1:
        bar[1]++;
        break;
      case 2:
        bar[2]++;
        break;
      case 3:
        bar[3]++;
        break;
      case 4:
        bar[4]++;
        break;
      default:
        break;
    }
  }
  return bar;
};

type FrequencyStatus = "GOOD" | "PARTIALLY_GOOD" | "UNKNOWN";
export const getFrequencyStatus = (radio: Radio): FrequencyStatus => {
  const cells = radio.runtimeInfo?.cellParameters;
  const configCells = radio.cellParameters;

  // if is unknown
  if (!radio.hasOwnProperty("serialNumber") || cells?.length === 0) {
    return UNKNOWN;
  }

  let status: FrequencyStatus = PARTIALLY_GOOD;
  const cellStatus: FrequencyStatus[] = [];
  cells?.forEach((_cell, i) => {
    const freq = _cell.earfcn;
    const configFreq = configCells?.[i]?.earfcn;

    if (freq !== configFreq?.[0]) {
      status = PARTIALLY_GOOD;
    } else {
      status = GOOD;
    }
    cellStatus.push(status);
  });

  if (cellStatus.includes(PARTIALLY_GOOD)) {
    return PARTIALLY_GOOD;
  } else if (cellStatus.includes(UNKNOWN)) {
    return UNKNOWN;
  } else {
    return GOOD;
  }
};

export const getOverallHealthData = (data: Radio[]) => {
  const status = {
    green: 0,
    red: 0,
    orange: 0,
    unknown: 0,
    thirdparty: 0,
  };

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const state = getRadioStatus(item as RadioX);

    switch (state) {
      case COLOR.GREEN:
        status.green++;
        break;
      case COLOR.RED:
        status.red++;
        break;
      case COLOR.ORANGE:
        status.orange++;
        break;
      case COLOR.UNKNOWN:
        status.unknown++;
        break;
      case COLOR.BLUE:
        status.thirdparty++;
        break;
      default:
        break;
    }
  }

  return status;
};

export function getOverallRadioHealth(radios: RadioX[]) {
  let green = 0;
  let orange = 0;
  let red = 0;
  let unknown = 0;
  let thirdparty = 0;
  for (let i = 0; i < radios?.length; i++) {
    const item = radios[i];
    if (!item?.isAddedToRadioManager && !item?.isManaged) {
      thirdparty++;
    } else if (!item?.isAddedToRadioManager && item?.isManaged) {
      unknown++;
    } else if (item?.statusColor === COLOR.GREEN) {
      green++;
    } else if (item?.statusColor === COLOR.ORANGE) {
      orange++;
    } else {
      red++;
    }
  }
  return {
    green,
    orange,
    red,
    unknown,
    thirdparty,
  };
}
