import { store } from "..";
import evt from "../event";

import { fetchRadioGroups, radioGroupActions, updateRadioGroups } from "./radioGroupSlice";
import { fetchEdgePools, fetchEdges } from "./edgeSlice";
import { fetchRadios } from "./radioSlice";
import { fetchDNN, fetchDevices } from "./subscriberSlice";
import { fetchDeviceGroups } from "./deviceGroupSlice";
import { fetchSites } from "./siteSlice";
import { utilityActions } from "./utilitySlice";
import { fetchRadioSimulator, fetchSimulator, fetchUESimulators } from "./simulatorSlice";
import { loadingActions } from "./loadingSlice";
import { fetchServiceController } from "./dashboardSlice";

evt.on("refreshRadios", () => {
  store.dispatch(fetchRadios());
});

evt.on("refreshRadioGroups", () => {
  store.dispatch(fetchRadioGroups());
});

evt.on("refreshSites", () => {
  store.dispatch(fetchSites());
});

evt.on("updateGroupAndRadio", () => {
  const state = store.getState();
  const grps = state.radioGroup.data;
  const radios = state.radio.data;
  const _grps = updateRadioGroups(grps, radios);
  store.dispatch(radioGroupActions.setData(_grps));
});

evt.on("refreshEdges", () => {
  store.dispatch(fetchEdges());
});

evt.on("refreshEdgePools", () => {
  store.dispatch(fetchEdgePools());
});

evt.on("refreshDevices", () => {
  store.dispatch(fetchDevices());
});

evt.on("refreshDeviceGroups", () => {
  store.dispatch(fetchDeviceGroups());
});

// evt.on("refreshDeviceGroupsAndDevices", async () => {
//   store.dispatch(fetchDeviceGroups());
//   await store.dispatch(fetchDevices());
// });

evt.on("setStartandEndTime", (startTime, endTime) => {
  store.dispatch(utilityActions.setStartTime(startTime));
  store.dispatch(utilityActions.setEndTime(endTime));
  store.dispatch(utilityActions.setTimeInterval({ interval : "custom"}));
});

evt.on("refreshRadioSimulator", () => {
  store.dispatch(fetchRadioSimulator());
});

evt.on("refreshSimulator", () => {
  store.dispatch(fetchSimulator());
});

evt.on("refreshUESimulators", () => {
  store.dispatch(fetchUESimulators());
});

evt.on("refreshDNN", () => {
  store.dispatch(fetchDNN());
});

evt.on("openSnackbar", (data) => {
  store.dispatch(loadingActions.setSnackBar({ ...data, open: true }));
});

evt.on("refreshServiceController", () => {
  store.dispatch(fetchServiceController());
});
