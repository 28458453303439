/**
 * Copyright Highway9 Networks Inc.
 */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { text } from "../../components/TextResources";
import ErrorsComponent from "../../components/ErrorsComponent";
import { Typography, Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useErrorsAndWarnings from "../../hooks/useErrorsAndWarnings";
import { userService } from "../../services";
import { GeneralDetails } from "./GeneralDetails";
import { CPIDetails } from "./CPIDetails";
import { DialogActions, DialogContent, DialogTitle } from "~/styles/Dialog";
import TabPanel, { a11yProps } from "~/components/TabPanel";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { selectTimeZone, utilityActions } from "~/store/context/utilitySlice";
import moment from "moment-timezone";


const EditProfile = (props) => {
  const theme = useTheme();

  const [user, setUser] = useState({});
  const [cpiDetails, setCpiDetails] = useState({});
  const [timezone, setTimeZone] = useState();
  const { errors, warnings, setErrorsAndWarnings, closeAlert, closeAll } = useErrorsAndWarnings();
  const [value, setValue] = React.useState(0);
  
  const dispatch = useAppDispatch();
  const tz = useAppSelector(selectTimeZone);

  useEffect(() => {
    if (props.state){
      getUserInfo();
      setErrorsAndWarnings([],[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state]);

  async function getUserInfo() {
      try {
        const userInfo = await userService.getUserInfo()
        setUser(userInfo);
        if (userInfo.cpiDetails) {
          setCpiDetails(userInfo.cpiDetails);
        }
      } catch (error) {
        console.log(error);
      }
    }
  const clearCPIDetails = ()=>{
    setCpiDetails({})
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async () => {
    try {
      var requestBody = {
        id : user.id,
        name: user.name,
        email: user.email,
        roles: user.roles,
        authType: user.authType,
        cpiDetails,
      };
      if(!Object.keys(cpiDetails).length){
        delete requestBody.cpiDetails
      }

      requestBody.timezone = timezone;

      await userService.updateUserInfo(requestBody);
      dispatch(utilityActions.setTimeZone(timezone || moment.tz.guess() ));
      moment.tz.setDefault(timezone);
      props.handleClose();
    } catch (error) {
      console.log("error===", error);
      setErrorsAndWarnings(error.errors, error.warnings);
    }

    getUserInfo();
  };

  useEffect(() => {
    setTimeZone(tz);
  },[tz])

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={props.state} fullWidth >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose} style={{padding: "1rem"}}>
        <Typography variant="h6" style={{ verticalAlign: "top" }}>
          {text.editProfile}
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{padding: "1rem"}}>
        <ErrorsComponent errorsData={errors} warningsData={warnings} closeAll={closeAll} closeAlert={closeAlert} />

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs">
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="CPI" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <GeneralDetails user={user} timezone={timezone} changeTimezone={setTimeZone} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <CPIDetails user={user} cpiDetails={cpiDetails} open={props.state} clearCPIDetails={clearCPIDetails} />
        </TabPanel>
      </DialogContent>
      <DialogActions style={{padding: "1rem"}}>
        <Button variant="contained" autoFocus onClick={handleSubmit} color="primary">
          {text.updateProfile_btn}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfile;
