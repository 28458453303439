// @ Copyright Highway 9 Networks

import { userService } from "~/services";
import { store } from "~/store";
import { utilityActions } from "~/store/context/utilitySlice";
import evt from "~/store/event";

function onAppStart() {
  console.log("App started");

  // Get query params
  const params = new URLSearchParams(window.location.search);

  const startTime = params.get("startTime");
  if (startTime) {
    store.dispatch(utilityActions.setStartTime(Number(startTime)));
  }

  const endTime = params.get("endTime");
  if (endTime) {
    store.dispatch(utilityActions.setEndTime(Number(endTime)));
  }

  const interval = params.get("interval");
  if (interval) {
    store.dispatch(utilityActions.setTimeInterval({ interval }));
  }

  const aggregationPeriod = params.get("aggregationPeriod");
  if (aggregationPeriod) {
    store.dispatch(utilityActions.setAggregationPeriod(aggregationPeriod));
  }
  
  const id = params.get("id");
  if (id) {
    store.dispatch(utilityActions.setSelectedID(id));
  }

  // fetch on startup
  evt.emit("refreshRadios");  // this refresh itself as it is bound to observe by radio simulator for the first time.
  evt.emit("refreshSites");
  evt.emit("refreshEdges");
  evt.emit("refreshServiceController");

  // update timezone
  updateTimezone();
}

export default onAppStart;


async function updateTimezone() {
  const userInfo = await userService.getUserInfo();
  if (userInfo.timezone) {
    store.dispatch(utilityActions.setTimeZone(userInfo.timezone));
    return;
  }
}